/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { urlToObject as e, getOrigin as r, removeTrailingSlash as t, removeQueryParameters as s, join as n, Url as l, makeAbsolute as i } from "../../core/urlUtils.js";
import { w as u } from "../../chunks/persistableUrlUtils.js";
const o = {
    mapserver: "MapServer",
    imageserver: "ImageServer",
    featureserver: "FeatureServer",
    knowledgegraphserver: "KnowledgeGraphServer",
    sceneserver: "SceneServer",
    streamserver: "StreamServer",
    vectortileserver: "VectorTileServer",
    "3dtilesserver": "3DTilesServer",
    videoserver: "VideoServer"
  },
  a = Object.values(o),
  c = new RegExp(`^((?:https?:)?\\/\\/\\S+?\\/rest\\/services\\/(.+?)\\/(${a.join("|")}))(?:\\/(?:layers\\/)?(\\d+))?`, "i"),
  v = new RegExp(`^((?:https?:)?\\/\\/\\S+?\\/([^/\\n]+)\\/(${a.join("|")}))(?:\\/(?:layers\\/)?(\\d+))?`, "i"),
  f = /(.*?)\/(?:layers\/)?(\d+)\/?$/i;
function p(e) {
  return c.test(e);
}
function d(r) {
  if (null == r) return null;
  const t = e(r),
    s = t?.path.match(c) || t?.path.match(v);
  if (!s) return null;
  const [, n, l, i, u] = s,
    a = l.indexOf("/");
  return {
    title: m(-1 !== a ? l.slice(a + 1) : l),
    serverType: o[i.toLowerCase()],
    sublayer: null != u && "" !== u ? parseInt(u, 10) : null,
    url: {
      path: n
    }
  };
}
function h(r) {
  const t = e(r).path.match(f);
  return t ? {
    serviceUrl: t[1],
    sublayerId: Number(t[2])
  } : null;
}
function m(e) {
  return (e = e.replaceAll(/\s*[/_]+\s*/g, " "))[0].toUpperCase() + e.slice(1);
}
function w(e, r) {
  const t = [];
  if (e) {
    const r = d(e);
    null != r && r.title && t.push(r.title);
  }
  if (r) {
    const e = m(r);
    t.push(e);
  }
  if (2 === t.length) {
    if (t[0].toLowerCase().includes(t[1].toLowerCase())) return t[0];
    if (t[1].toLowerCase().includes(t[0].toLowerCase())) return t[1];
  }
  return t.join(" - ");
}
function g(e) {
  let t = r(e, !0);
  return !!t && (t = t.toLowerCase(), t.endsWith(".arcgis.com") && (t.startsWith("services") || t.startsWith("tiles") || t.startsWith("features")));
}
function y(e, r) {
  return e ? t(s(e, r)) : e;
}
function S(r) {
  let {
    url: n
  } = r;
  if (!n) return {
    url: n
  };
  n = s(n, r.logger);
  const l = e(n),
    i = d(l.path);
  let u;
  if (null != i) null != i.sublayer && null == r.layer.layerId && (u = i.sublayer), n = i.url.path;else if (r.nonStandardUrlAllowed) {
    const e = h(l.path);
    null != e && (n = e.serviceUrl, u = e.sublayerId);
  }
  return {
    url: t(n),
    layerId: u
  };
}
function C(e, r, t, s, l) {
  u(r, s, "url", l), s.url && null != e.layerId && (s.url = n(s.url, t, e.layerId.toString()));
}
function b(e) {
  if (!e) return !1;
  const r = e.toLowerCase(),
    t = r.includes("/services/"),
    s = r.includes("/mapserver/wmsserver"),
    n = r.includes("/imageserver/wmsserver"),
    l = r.includes("/wmsserver");
  return t && (s || n || l);
}
function I(e) {
  if (!e) return !1;
  const r = new l(i(e)),
    t = r.authority?.toLowerCase();
  return "server.arcgisonline.com" === t || "services.arcgisonline.com" === t;
}
export { m as cleanTitle, p as isArcGISUrl, g as isHostedAgolService, I as isServerOrServicesAGOLUrl, b as isWmsServer, d as parse, h as parseNonStandardSublayerUrl, y as sanitizeUrl, S as sanitizeUrlWithLayerId, a as serverTypes, w as titleFromUrlAndName, C as writeUrlWithLayerId };